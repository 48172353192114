@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// fontawesome
$fa-font-path: "../webfonts";
@import "fontawesome";
@import "solid";
@import "regular";

// functions
@function header-font-size($breakpoint, $size) {
  @return map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px;
}

@function get-harf-margin-top($header-font-size) {
  @return -$header-font-size * $header-lineheight / 2 ;
}

@function get-harf-margin-left($header-font-size, $count) {
  @return -$header-font-size * $count / 2 ;
}

@mixin get-margin($type, $count){
  $header-font-size: header-font-size("small", $type);
  margin-top: get-harf-margin-top($header-font-size);
  margin-left: get-harf-margin-left($header-font-size, $count);
  @include breakpoint(medium){
    $header-font-size: header-font-size("medium", $type);
    margin-top: get-harf-margin-top($header-font-size);
    margin-left: get-harf-margin-left($header-font-size, $count);
  }
}

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/header";
@import "modules/footer";

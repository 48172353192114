#footer {
  background-color: $primary-color;
  color: $white;
  #copyright {
    text-align: center;
  }
  .site_name {
    font-size: header-font-size(small, h1);
    margin: 3rem 0;
    @include breakpoint(medium){
      font-size: header-font-size(medium, h1);
    }
  }
  .cell {
    margin: 2rem 0;
  }
}

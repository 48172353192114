.border-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  padding: .5rem;
  height: 100%;
  @include breakpoint(medium) {
    padding: $global-padding;
  }
  p {
    margin: 0;
  }
}

#header {
  height: 200px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include breakpoint(medium) {
    height: 300px;
  }
  .overlay {
    height: 100%;
    background-color: $orbit-caption-background;
    position: relative;
    padding: .5rem;
    h1 {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      text-align: center;
      color: $white;
      margin: 0;
      @include get-margin(h1, 4);
    }
  }
}

.about {
  #header {
    background-image: url(../img/AdobeStock_140728735.jpg);
  }
}

.works {
  #header {
    background-image: url(../img/AdobeStock_136871944.jpg);
  }
}

.orbit-caption {
  color: $white;
  padding: .5rem;
  top: 0;
  right: 0;
  width: 45%;
  height: 80%;
  @include breakpoint(medium){
    top: 2%;
    right: 2%;
    height: 60%;
    font-size: header-font-size(medium, h4);
  }
  @include breakpoint(large){
    top: 2%;
    right: 2%;
    height: 40%;
    font-size: header-font-size(medium, h3);
  }
}

section {
  padding: 2rem 1rem;
}

h2 {
  color: $primary-color;
  margin: 1rem 0;
}

dl {
  dt, dd {
    padding: .5rem 1rem;
  }
  dt {
    font-weight: normal;
    background-color: $success-color;
    border-bottom: 1px solid $white;
    margin-bottom: 0px;
    @include breakpoint(medium) {
      text-align: right;
      padding-right: 2rem;
    }
  }
  dd {
    border: 1px solid $success-color;
    @include breakpoint(medium) {
      margin-bottom: 0px;
    }
    ul {
      margin-bottom: 0;
    }
  }
}

.site_name {
  small {
    margin-right: .2rem;
  }
}

.container {
  @include xy-grid-container;
}

.card {
  .card-image {
    text-align: center;
    max-height: 600px;
    overflow: hidden;
    img {
      // width: 100%;

    }
  }
}

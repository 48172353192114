.top-bar {
  .top-bar-left {
    .menu {
      justify-content: space-between;
      li {
        margin: .2rem 0;
        &.menu-text {
          padding: 0;
          font-weight: normal;
          a {
            color: $primary-color;
            font-size: 1.4rem;
            padding: .5rem;
          }
        }
        &.menu-item {
          a {
            position: relative;
            color: $black;
            padding-left: 0;
            @include breakpoint(medium) {
              padding-left: 1rem;
            }
            &::after {
              position: absolute;
              display: block;
              bottom: 0;
              left: 0;
              width: 0;
              height: 1px;
              content: '';
              background: $primary-color;
              transition: left .5s, width .5s;
              @include breakpoint(medium) {
                left: 50%;
              }
            }
            &:hover, &:focus {
              &::after {
                width: 10%;
                @include breakpoint(medium) {
                  width: 50%;
                  left: 25%;
                }
              }
            }
          }
        }
        &.menu-contact {
          a {
            @include button(false, $button-background, $white, $white, solid);
            font-size: 1.2rem;
            padding: $global-menu-padding;
            margin-bottom: 0;
            &::before {
              @extend %fa-icon;
              @extend .fas;
              content: fa-content($fa-var-phone);
              margin-right: .5rem;
            }
            &:hover, &:focus {
              color: $primary-color;
              border: 1px solid $primary-color;
              @include breakpoint(medium){
              }
            }
          }
        }
      }
    }
  }
}
.title-bar {
  padding-left: 0;
}

.sticky-container {
  background-color: $topbar-background;
  z-index: 5;
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
